import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
    GameReviewsResponse,
    IdResponse,
    Page,
    ReviewCountResponse,
    ReviewResponse,
} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {PostRequest, ReviewRequest,} from "../../model/requests";
import {ReviewSort} from "../../model/review-sort";

@Injectable()
export class ReviewService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getReview(reviewId: string): Observable<ReviewResponse> {
        return this.http.get<ReviewResponse>(`${environment.apiUrl}/reviews/${reviewId}`);
    }

    getUserReviews(userId: string, sort?: ReviewSort): Observable<Page<ReviewResponse>> {
        let params = new HttpParams()
        if (sort) {
            params = params.set('sort', sort)
        }
        return this.http.get<Page<ReviewResponse>>(`${environment.apiUrl}/reviews/user/${userId}`, {params: params});
    }

    getUserReviewCount(userId: string): Observable<ReviewCountResponse> {
        return this.http.get<ReviewCountResponse>(`${environment.apiUrl}/reviews/user/${userId}/count`);
    }

    getGameReviews(gameId: string): Observable<GameReviewsResponse> {
        return this.http.get<GameReviewsResponse>(`${environment.apiUrl}/reviews/game/${gameId}`);
    }

    createReview(request: ReviewRequest, attachments: Blob[]): Observable<IdResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        for (let i = 0; i < attachments.length; i++) {
            formData.append(`attachment-${i}`, attachments[i]);
        }
        return this.http.post<IdResponse>(`${environment.apiUrl}/reviews`, formData);
    }

    updateReview(reviewId: string, request: ReviewRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/reviews/${reviewId}`, request);
    }

    deleteReview(reviewId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/reviews/${reviewId}`);
    }
}
